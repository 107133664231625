import Project from '../../../../../../models/project/Project';
import { featuresService, multiLingualService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import { SportTypes } from '../../../../../../constants/sport.types';
import BlockConfigModel from '../../../models/block-config.model/block-config.model';
import moment from 'moment';
import { WidgetsV2Ids } from '../../../constants/block.types';
import { WIDGET_ATTRIBUTE_NAMES, WIDGET_PREVIEW_ATTRIBUTE_NAMES } from './component-attributes.constants';
import REACT_APP_URLS from '../../../../../../global-helpers/global-url.helpers';
import { MatchTypes, WidgetPlayerTabs, WidgetTeamTabs } from '../football-team/constants/football-team.constants';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { constructDataAttribute as constructDataAttributeImported } from './block-v2-config.helper';
import { buildOddsDataAttributes, widgetIdsWithOddsOptions } from './block-v2-config.helper';
import { LINEUPS_ELEMENTS } from '../../partials/lineups-elements/lineups-elements-toggle.component';
import { isValueEmpty } from '../../../../../../global-helpers/global.helpers';

const monoRepoUrl = REACT_APP_URLS.REACT_APP_WIDGETS_MONOREPO_URL;
const storybookUndefinedValue = '!undefined';

// will remove double spacing between attributes
const cleanAttributes = (htmlString: string) => htmlString.replace(/\s+/g, ' ');

// checks if spacing between attributes is correct
const isSeperatedCorrectly = (htmlString: string) => {
	const tagContent = htmlString.split('<div ')[1];
	const attributes = tagContent.split(' ');

	for (let i = 1; i < attributes.length; i++) {
		const prevAttribute = attributes[i - 1];
		const currentAttribute = attributes[i];

		if (prevAttribute.trim().length === 0 || currentAttribute.trim().length === 0) {
			return false;
		}
	}
	return true;
};

export const getFormattedContentData = (value: string): string => {
	if (isSeperatedCorrectly(value)) {
		return value;
	}
	return cleanAttributes(value);
};

export const constructBasketballEventWidgetContent = (config: BlockConfigModel) => {
	const { oddsDisplay, oddsBettingId, oddsMarket } = buildOddsDataAttributes(config, true);
	const widgetRefreshTime = constructDataAttributeImported(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);

	let contentData = `<div data-widget-id='${config.widgetId}' data-match-id='${config.options.gameId}' data-widget-type='event' data-widget-sport='${SportTypes.BASKETBALL}'${widgetRefreshTime}`;

	const configSettings = [oddsBettingId, oddsDisplay, oddsMarket];

	configSettings.forEach((item: any) => {
		contentData = addValueIfNotEmpty(item, contentData);
	});
	contentData += '></div>';

	return getFormattedContentData(contentData);
};

export const constructBasketballTournamentWidgetContent = (config: BlockConfigModel) => {
	const date = config.options.date && JSON.stringify({ date: moment(config.options.date).format('DD-MM-YYYY'), dateFormat: 'DD-MM-YYYY' });
	const { oddsDisplay, oddsBettingId } = buildOddsDataAttributes(config);
	const dataConfigDate = date ? constructDataAttribute(date, WIDGET_ATTRIBUTE_NAMES.DATE) : ``;
	const dataConfigCompetition = constructDataAttribute(config.options.competitionId, WIDGET_ATTRIBUTE_NAMES.COMPETITION);
	const dataConfigSeason = constructDataAttribute(config.options.seasonId, WIDGET_ATTRIBUTE_NAMES.SEASON);
	const dataConfigStage = constructDataAttribute(config.options.stageId, WIDGET_ATTRIBUTE_NAMES.STAGE);
	const widgetRefreshTime = constructDataAttributeImported(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);
	const dataRoundsFilter =
		WidgetsV2Ids.widgetBasketballTournamentProgramme || WidgetsV2Ids.widgetBasketballTeamProgramme
			? constructDataAttribute(JSON.stringify(config.options.roundsFilter), WIDGET_ATTRIBUTE_NAMES.ROUNDS_FILTER)
			: '';
	const dataConfigSortDirection = constructDataAttribute(config.options.sortDirection, WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION);
	const dataConfigSortDirectionFixtures = constructDataAttribute(
		config.options.sortDirectionFixtures,
		WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION_FIXTURES,
	);
	const dataConfigSortDirectionResults = constructDataAttribute(
		config.options.sortDirectionResults,
		WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION_RESULTS,
	);
	const dataHeaderDisplay = constructDataAttributeImported(config, config.options.displayHeader, WIDGET_ATTRIBUTE_NAMES.HEADER_DISPLAY);
	const headerDefaultOption = checkIfCorrectWidgetTypes(config.widgetId, [
		WidgetsV2Ids.widgetBasketballStandings,
		WidgetsV2Ids.widgetBasketballLivescore,
		WidgetsV2Ids.widgetBasketballTeamProgramme,
		WidgetsV2Ids.widgetBasketballTournamentProgramme,
	])
		? constructDataAttribute(config.options.headerDefaultOption, WIDGET_ATTRIBUTE_NAMES.HEADER_DEFAULT_OPTION)
		: '';
	const matchType =
		config.options.matchType && config.options.matchType !== MatchTypes.BOTH
			? constructDataAttribute(config.options.matchType, WIDGET_ATTRIBUTE_NAMES.MATCH_TYPE)
			: '';
	const dataTeam = config.options.teamId ? constructDataAttribute(config.options.teamId, WIDGET_ATTRIBUTE_NAMES.TEAM) : '';

	return getFormattedContentData(
		`<div data-widget-id='${config.widgetId}' data-widget-sport='${SportTypes.BASKETBALL}' data-widget-type='tournament' ${dataConfigDate}${oddsDisplay}${oddsBettingId}${dataConfigCompetition}${dataConfigSeason}${dataConfigStage}${dataHeaderDisplay}${headerDefaultOption}${dataRoundsFilter}${dataConfigSortDirection}${dataConfigSortDirectionFixtures}${dataConfigSortDirectionResults}${matchType}${dataTeam}${widgetRefreshTime}></div>`,
	);
};

export const constructTennisTournamentWidget = (config: BlockConfigModel) => {
	const date = config.options.date
		? constructDataAttribute(
				JSON.stringify({ date: moment(config.options.date).format('DD.MM.YYYY'), dateFormat: 'DD.MM.YYYY' }),
				WIDGET_ATTRIBUTE_NAMES.DATE,
		  )
		: ``;
	const dataHeaderDisplay = constructDataAttributeImported(config, config.options.displayHeader, WIDGET_ATTRIBUTE_NAMES.HEADER_DISPLAY);
	const { oddsDisplay, oddsBettingId } = buildOddsDataAttributes(config);

	const limit = WidgetsV2Ids.widgetTennisRanking ? constructDataAttribute(config.options.limit, WIDGET_ATTRIBUTE_NAMES.LIMIT) : '';
	const widgetRefreshTime = constructDataAttributeImported(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);
	const offset = WidgetsV2Ids.widgetTennisRanking ? constructDataAttribute(config.options.offset, WIDGET_ATTRIBUTE_NAMES.OFFSET) : '';

	const standingType = WidgetsV2Ids.widgetTennisRanking
		? constructDataAttribute(config.options.standingType, WIDGET_ATTRIBUTE_NAMES.STANDING_TYPE)
		: '';

	const constructedHighlightPlayers =
		config.options.highlightPlayers && config.options.highlightPlayers.length > 0
			? `[${config.options.highlightPlayers.map((id: string) => `"${id}"`)}]`
			: '[]';

	const headerDefaultOption = checkIfCorrectWidgetTypes(config.widgetId, [
		WidgetsV2Ids.widgetTennisLivescore,
		WidgetsV2Ids.widgetTennisAthleteProgramme,
		WidgetsV2Ids.widgetTennisTournamentProgramme,
	])
		? constructDataAttribute(config.options.headerDefaultOption, WIDGET_ATTRIBUTE_NAMES.HEADER_DEFAULT_OPTION)
		: '';

	const highlightPlayers = WidgetsV2Ids.widgetTennisRanking
		? constructDataAttribute(constructedHighlightPlayers, WIDGET_ATTRIBUTE_NAMES.HIGHLIGHT_PLAYERS)
		: '';

	let contentData = `<div data-widget-id='${config.widgetId}' data-widget-sport='${SportTypes.TENNIS}' data-widget-type='tournament'${widgetRefreshTime}${headerDefaultOption}`;

	const configSettings = [date, oddsBettingId, oddsDisplay, dataHeaderDisplay, limit, offset, standingType, highlightPlayers];
	configSettings.forEach((item: any) => {
		contentData = addValueIfNotEmpty(item, contentData);
	});
	contentData += '></div>';

	return getFormattedContentData(contentData);
};

export const constructTennisSingleEventContent = (config: BlockConfigModel) => {
	const { oddsDisplay, oddsBettingId, oddsMarket } = buildOddsDataAttributes(config, true);
	const widgetRefreshTime = constructDataAttributeImported(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);

	let contentData = `<div data-widget-id='${config.widgetId}' data-match-id='${config.options.matchId}' data-widget-type='event' data-widget-sport='${SportTypes.TENNIS}'${widgetRefreshTime}`;
	const configSettings = [oddsDisplay, oddsBettingId, oddsMarket];

	configSettings.forEach((item: any) => {
		contentData = addValueIfNotEmpty(item, contentData);
	});

	contentData += '></div>';

	return getFormattedContentData(contentData);
};

export const constructTennisPlayoffWidgetContent = (config: BlockConfigModel) => {
	const dataConfigCompetition = constructDataAttribute(config.options.competitionId, WIDGET_ATTRIBUTE_NAMES.COMPETITION);
	const dataConfigSeason = constructDataAttribute(config.options.seasonId, WIDGET_ATTRIBUTE_NAMES.SEASON);
	const dataConfigStage = constructDataAttribute(config.options.stageId, WIDGET_ATTRIBUTE_NAMES.STAGE);
	const dataHeaderDisplay = constructDataAttribute(config.options.displayHeader, WIDGET_ATTRIBUTE_NAMES.HEADER_DISPLAY);
	const widgetRefreshTime = constructDataAttributeImported(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);

	return getFormattedContentData(
		`<div data-widget-id='${config.widgetId}' data-widget-sport='${SportTypes.TENNIS}' data-widget-type='tournament' ${dataConfigCompetition}${dataConfigSeason}${dataConfigStage}${dataHeaderDisplay}${widgetRefreshTime}></div>`,
	);
};

export const constructTennisProgrammeWidgetContent = (config: BlockConfigModel) => {
	const dataConfigPlayer = checkIfCorrectWidgetType(config.widgetId, WidgetsV2Ids.widgetTennisAthleteProgramme)
		? constructDataAttribute(config.options.playerId, WIDGET_ATTRIBUTE_NAMES.PLAYER)
		: ``;
	const dataConfigCompetition = constructDataAttribute(config.options.competitionId, WIDGET_ATTRIBUTE_NAMES.COMPETITION);
	const dataConfigSeason = constructDataAttribute(config.options.season, WIDGET_ATTRIBUTE_NAMES.SEASON);
	const dataConfigTournament = constructDataAttribute(config.options.tournamentId, WIDGET_ATTRIBUTE_NAMES.TOURNAMENT);
	const dataRoundsFilter =
		config.options.roundsFilter && config.options.roundsFilter.length > 0
			? constructDataAttribute(JSON.stringify(config.options.roundsFilter), WIDGET_ATTRIBUTE_NAMES.ROUNDS_FILTER)
			: ``;
	const dataConfigSortDirection = constructDataAttribute(config.options.sortDirection, WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION);
	const dataConfigSortDirectionFixtures = constructDataAttribute(
		config.options.sortDirectionFixtures,
		WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION_FIXTURES,
	);
	const dataConfigSortDirectionResults = constructDataAttribute(
		config.options.sortDirectionResults,
		WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION_RESULTS,
	);
	const oddsBettingId =
		config.options.displayOdds && constructDataAttribute(config.options.oddProviderIds, WIDGET_ATTRIBUTE_NAMES.BETTING_ID);
	const oddsDisplay = constructDataAttribute(config.options.displayOdds, WIDGET_ATTRIBUTE_NAMES.ODDS_DISPLAY);
	const dataHeaderDisplay = constructDataAttribute(config.options.displayHeader, WIDGET_ATTRIBUTE_NAMES.HEADER_DISPLAY);
	const widgetRefreshTime = constructDataAttributeImported(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);
	const widgetGameType = constructDataAttributeImported(config, config.options.gameType, WIDGET_ATTRIBUTE_NAMES.GAME_TYPE);
	const widgetGender = constructDataAttributeImported(config, config.options.gender, WIDGET_ATTRIBUTE_NAMES.GENDER);

	const headerDefaultOption = constructDataAttribute(config.options.headerDefaultOption, WIDGET_ATTRIBUTE_NAMES.HEADER_DEFAULT_OPTION);

	let contentData = `<div data-widget-id='${config.widgetId}' data-widget-sport='${SportTypes.TENNIS}' data-widget-type='tournament'${widgetRefreshTime}${headerDefaultOption}${widgetGameType}${widgetGender}`;

	const configSettings = [
		dataConfigPlayer,
		dataConfigCompetition,
		dataConfigSeason,
		dataConfigTournament,
		dataRoundsFilter,
		dataConfigSortDirection,
		dataConfigSortDirectionFixtures,
		dataConfigSortDirectionResults,
		oddsBettingId,
		oddsDisplay,
		dataHeaderDisplay,
	];

	configSettings.forEach((item: any) => {
		contentData = addValueIfNotEmpty(item, contentData);
	});
	contentData += '></div>';

	return getFormattedContentData(contentData);
};

export const sportsConfigFromProject = (project: Project, sport: string) => {
	const config = featuresService.getFeatureConfig(sport);
	const SPORTS_URL = returnCorrectSportApiUrl(project, sport);
	const auth = config.authentication && `${config.authentication.username}:${config.authentication.password}`;
	const competitionList =
		config.competitionList && config.competitionList.length > 0 ? config.competitionList : project.defaultTournamentOrder;
	return {
		lang:
			multiLingualService.checkIfProjectIsMultiLingual(project.languages) && multiLingualService.checkRoutePath()
				? multiLingualService.setCorrectAcceptLanguageCode(project.languages.defaultLanguageCode.languageCode)
				: project.language.split('-')[0],
		apiKey: auth,
		apiURL: SPORTS_URL,
		defaultCompetitionList: competitionList,
		timezone: project.timezone,
		project: project.domain,
		oddClient: config.oddClient ? config.oddClient : null,
	};
};

export const addValueIfNotEmpty = (value: string, string: string) => {
	if (value && value.length > 0) {
		return string.concat(value);
	} else {
		return string;
	}
};

export const returnCorrectSportApiUrl = (project: Project, sport: string) => {
	switch (sport) {
		case FeatureTypes.FOOTBALL_V2:
			return project.footballApiUrl;
		case FeatureTypes.BASKETBALL:
			return REACT_APP_URLS.REACT_APP_BASKETBALL_API_URL;
		case FeatureTypes.TENNIS:
			return REACT_APP_URLS.REACT_APP_TENNIS_API_URL;
	}
};

export const checkIfCorrectWidgetTypes = (widgetId: string, correctWidgetIds: string[]) => {
	return correctWidgetIds.includes(widgetId);
};

export const constructDataPreviewRoundsFilterAttribute = (attributeValue: any, attributeName: string, widgetId: string) => {
	let stageOrTournamentQuery = '';
	let roundsFilterQuery = '';
	if (attributeValue && attributeValue.length > 0) {
		attributeValue.forEach((item: any, index: number) => {
			stageOrTournamentQuery = constructDataPreviewStageOrTournamentrAttribute(item, widgetId);
			return (roundsFilterQuery += `${attributeName}[${index}].roundId:${item.roundId};${WIDGET_PREVIEW_ATTRIBUTE_NAMES.ROUNDS_FILTER}[${index}].${stageOrTournamentQuery};`);
		});

		return roundsFilterQuery;
	}

	return `${WIDGET_PREVIEW_ATTRIBUTE_NAMES.ROUNDS_FILTER}:${storybookUndefinedValue};`;
};

export const constructDataPreviewStandingTypeAttribute = (attributeValue: any) => {
	if (attributeValue && attributeValue.length > 0) {
		return `${WIDGET_PREVIEW_ATTRIBUTE_NAMES.STANDING_TYPE_SUBCATEGORY}${attributeValue};`;
	}

	return `${WIDGET_PREVIEW_ATTRIBUTE_NAMES.STANDING_TYPE_SUBCATEGORY}${storybookUndefinedValue};`;
};

export const constructDataPreviewStageOrTournamentrAttribute = (item: any, widgetId: string) => {
	let stageOrTournamentQuery = '';
	switch (widgetId) {
		case WidgetsV2Ids.widgetBasketballTournamentProgramme:
		case WidgetsV2Ids.widgetBasketballTeamProgramme:
			return (stageOrTournamentQuery = `stageId:${item.stageId}`);
		case WidgetsV2Ids.widgetTennisAthleteProgramme:
		case WidgetsV2Ids.widgetTennisTournamentProgramme:
			return (stageOrTournamentQuery = `tournamentId:${item.tournamentId}`);
	}

	return stageOrTournamentQuery;
};

export const checkIfCorrectWidgetType = (widgetId: string, correctWidgetIds: string) => {
	return widgetId === correctWidgetIds;
};

export const constructDataPreviewAttribute = (attributeValue: any, attributeName: string) => {
	return attributeValue ? `${attributeName}${attributeValue};` : '';
};

export const constructDataTabsArrayPreviewAttribute = (
	tabsArray: string[],
	attributeName: string,
	WidgetTabsFunction: (t: any) => SelectMenuOptionType[],
) => {
	const allAvailableOptions = WidgetTabsFunction(() => undefined).map((el) => el.value.toLocaleString());
	const tabsArrayOverwrite = overwriteStorybookTabsElements(tabsArray, allAvailableOptions);
	return tabsArrayOverwrite.map(({ label, value }) => {
		const resultValue = value === storybookUndefinedValue ? value : value.toLocaleUpperCase();
		return `${attributeName}${label}:${resultValue};`;
	});
};

export const constructDataAttribute = (attributeValue: any, attributeName: string) => {
	return !isValueEmpty(attributeValue) ? `${attributeName}'${attributeValue}'` : '';
};

export const buildOddProviderIds = (config: BlockConfigModel) => {
	return constructDataPreviewAttribute(config.options.oddProviderIds, WIDGET_PREVIEW_ATTRIBUTE_NAMES.BETTING_ID);
};

export const buildOddsParametersIframeUrl = (config: BlockConfigModel) => {
	const widgetId = config.widgetId;
	if (widgetIdsWithOddsOptions.includes(widgetId as WidgetsV2Ids)) {
		let oddDisplay = config.options.displayOdds
			? constructDataPreviewAttribute(config.options.displayOdds, WIDGET_PREVIEW_ATTRIBUTE_NAMES.ODDS_DISPLAY)
			: `dataOddsDisplay:${false};`;
		let oddProviderId = config.options.displayOdds ? buildOddProviderIds(config) : ``;
		let mainOddProviderId = config.options.displayOdds
			? constructDataPreviewAttribute(config.options.mainOddProvider, WIDGET_PREVIEW_ATTRIBUTE_NAMES.MAIN_ODDS_BETTING_PROVIDER_ID)
			: ``;
		let oddMarket =
			config.options.displayOdds && config.options.market
				? constructDataPreviewAttribute(config.options.market, WIDGET_PREVIEW_ATTRIBUTE_NAMES.MARKET)
				: ``;

		let marketValueType =
			config.options.displayOdds && config.options.marketValueType
				? constructDataPreviewAttribute(config.options.marketValueType.value, WIDGET_PREVIEW_ATTRIBUTE_NAMES.MARKET_VALUE_TYPE)
				: '';

		if (widgetId === WidgetsV2Ids.widgetFootballOdds) {
			oddProviderId = buildOddProviderIds(config);
			oddMarket = config.options.market && constructDataPreviewAttribute(config.options.market.value, WIDGET_PREVIEW_ATTRIBUTE_NAMES.MARKET);
			marketValueType = constructDataPreviewAttribute(config.options.marketValueType.value, WIDGET_PREVIEW_ATTRIBUTE_NAMES.MARKET_VALUE_TYPE);
		}

		return { oddDisplay, oddProviderId, mainOddProviderId, oddMarket, marketValueType };
	}

	return { oddDisplay: '', oddProviderId: '', mainOddProviderId: '', oddMarket: '', marketValueType: '' };
};

export const buildMonoRepoIframeUrl = (block: BlockConfigModel, sport: string) => {
	const eventId = constructDataPreviewAttribute(returnCorrectEventPropertyByBlockType(block), WIDGET_PREVIEW_ATTRIBUTE_NAMES.MATCH);

	const { oddProviderId, mainOddProviderId, oddDisplay, oddMarket, marketValueType } = buildOddsParametersIframeUrl(block);

	const date = checkIfCorrectWidgetTypes(block.widgetId, [
		WidgetsV2Ids.widgetFootballLivescore,
		WidgetsV2Ids.widgetBasketballLivescore,
		WidgetsV2Ids.widgetTennisLivescore,
	])
		? constructDataPreviewAttribute(moment(block.options.date).format('DD-MM-YYYY'), WIDGET_PREVIEW_ATTRIBUTE_NAMES.DATE)
		: ``;
	const competitionId = constructDataPreviewAttribute(block.options.competitionId, WIDGET_PREVIEW_ATTRIBUTE_NAMES.COMPETITION);
	const seasonId = constructDataPreviewAttribute(block.options.seasonId, WIDGET_PREVIEW_ATTRIBUTE_NAMES.SEASON);
	const stageId = constructDataPreviewAttribute(block.options.stageId, WIDGET_PREVIEW_ATTRIBUTE_NAMES.STAGE);
	const roundId = checkIfCorrectWidgetTypes(block.widgetId, [
		WidgetsV2Ids.widgetFootballTournamentProgramme,
		WidgetsV2Ids.widgetFootballTeamProgramme,
	])
		? buildRoundsParameter(block.options.rounds)
		: ``;
	const teamId = constructDataPreviewAttribute(block.options.teamId, WIDGET_PREVIEW_ATTRIBUTE_NAMES.TEAM);
	const playerId = constructDataPreviewAttribute(block.options.playerId, WIDGET_PREVIEW_ATTRIBUTE_NAMES.PLAYER);
	const sortDirection = constructDataPreviewAttribute(
		block.options.sortDirection && block.options.sortDirection.toLowerCase(),
		WIDGET_PREVIEW_ATTRIBUTE_NAMES.SORT_DIRECTION,
	);
	const sortDirectionResults = constructDataPreviewAttribute(
		block.options.sortDirectionResults && block.options.sortDirectionResults.toLowerCase(),
		WIDGET_PREVIEW_ATTRIBUTE_NAMES.SORT_DIRECTION_RESULTS,
	);
	const sortDirectionFixtures = constructDataPreviewAttribute(
		block.options.sortDirectionFixtures && block.options.sortDirectionFixtures.toLowerCase(),
		WIDGET_PREVIEW_ATTRIBUTE_NAMES.SORT_DIRECTION_FIXTURES,
	);
	const fansUnitedEnabled = setFansUnitedEnabled(block, block.widgetId);
	const fansUnitedExpanded = setFansUnitedExpanded(block, block.widgetId);
	const groups = block.options.groups && block.options.groups.length > 0 ? buildStandingsGroupsParameter(block.options.groups) : ``;
	const headerDisplay = setDefaultHeaderDisplayOption(block.widgetId, block.options.displayHeader);
	const displayTabs =
		checkIfCorrectWidgetTypes(block.widgetId, [WidgetsV2Ids.widgetFootballTeam, WidgetsV2Ids.widgetFootballPlayer]) &&
		block.options.displayTabs
			? constructDataPreviewAttribute(block.options.displayTabs, WIDGET_PREVIEW_ATTRIBUTE_NAMES.DISPLAY_TABS)
			: `dataDisplayTabs:${false};`;
	const defaultTab =
		checkIfCorrectWidgetTypes(block.widgetId, [
			WidgetsV2Ids.widgetFootballTeam,
			WidgetsV2Ids.widgetFootballPlayer,
			WidgetsV2Ids.widgetFootballLineups,
		]) && block.options.displayTabs
			? constructDataPreviewAttribute(block.options.defaultTab, WIDGET_PREVIEW_ATTRIBUTE_NAMES.DEFAULT_TAB)
			: ``;
	const chosenTabs =
		checkIfCorrectWidgetTypes(block.widgetId, [WidgetsV2Ids.widgetFootballTeam, WidgetsV2Ids.widgetFootballPlayer]) &&
		block.options.displayTabs
			? constructDataTabsArrayPreviewAttribute(
					block.options.tabs,
					WIDGET_PREVIEW_ATTRIBUTE_NAMES.TABS,
					checkIfCorrectWidgetType(block.widgetId, WidgetsV2Ids.widgetFootballPlayer) ? WidgetPlayerTabs : WidgetTeamTabs,
			  ).join('')
			: ``;
	const headerDefaultOption =
		checkIfCorrectWidgetTypes(block.widgetId, [
			WidgetsV2Ids.widgetFootballLivescore,
			WidgetsV2Ids.widgetFootballMatchesH2H,
			WidgetsV2Ids.widgetFootballTeamProgramme,
			WidgetsV2Ids.widgetFootballTeamSquad,
			WidgetsV2Ids.widgetFootballTournamentProgramme,
			WidgetsV2Ids.widgetBasketballLivescore,
			WidgetsV2Ids.widgetBasketballStandings,
			WidgetsV2Ids.widgetBasketballTeamProgramme,
			WidgetsV2Ids.widgetBasketballTournamentProgramme,
			WidgetsV2Ids.widgetTennisAthleteProgramme,
			WidgetsV2Ids.widgetTennisLivescore,
			WidgetsV2Ids.widgetTennisTournamentProgramme,
		]) && block.options.headerDefaultOption
			? constructDataPreviewAttribute(block.options.headerDefaultOption, WIDGET_PREVIEW_ATTRIBUTE_NAMES.HEADER_DEFAULT_OPTION)
			: '';
	const homeTeamColor = checkIfCorrectWidgetType(block.widgetId, WidgetsV2Ids.widgetFootballLineups)
		? constructDataPreviewAttribute(block.options.homeTeamColor, WIDGET_PREVIEW_ATTRIBUTE_NAMES.HOME_TEAM_COLOR)
		: ``;
	const awayTeamColor = checkIfCorrectWidgetType(block.widgetId, WidgetsV2Ids.widgetFootballLineups)
		? constructDataPreviewAttribute(block.options.awayTeamColor, WIDGET_PREVIEW_ATTRIBUTE_NAMES.AWAY_TEAM_COLOR)
		: ``;
	const matchType =
		checkIfCorrectWidgetTypes(block.widgetId, [WidgetsV2Ids.widgetFootballTeamProgramme, WidgetsV2Ids.widgetBasketballTeamProgramme]) &&
		block.options.matchType !== MatchTypes.BOTH
			? constructDataPreviewAttribute(block.options.matchType, WIDGET_PREVIEW_ATTRIBUTE_NAMES.MATCH_TYPE)
			: ``;
	const highLightPlayers = block.options.highlightPlayers ? buildHighlightPlayersParameter(block.options.highlightPlayers) : ``;
	const standingTypeSubcategory = checkIfCorrectWidgetType(block.widgetId, WidgetsV2Ids.widgetTennisRanking)
		? constructDataPreviewStandingTypeAttribute(block.options.standingType)
		: '';
	const offset = constructDataPreviewAttribute(block.options.offset, WIDGET_PREVIEW_ATTRIBUTE_NAMES.OFFSET);
	const limit = block.options.limit ? constructDataPreviewAttribute(block.options.limit, WIDGET_PREVIEW_ATTRIBUTE_NAMES.LIMIT) : ``;
	const startPosition = constructDataPreviewAttribute(block.options.startFromPosition, WIDGET_PREVIEW_ATTRIBUTE_NAMES.START_POSITION);
	const elements = buildElementsParameter(block.options.elements || [], block.widgetId, block);
	const playerEntityOneId = setSportEntityWithSeasonParameter(
		block.options.player1Id,
		WIDGET_PREVIEW_ATTRIBUTE_NAMES.SPORT_ENTITY_ONE_ID,
		block.widgetId,
	);
	const playerEntityTwoId = setSportEntityWithSeasonParameter(
		block.options.player2Id,
		WIDGET_PREVIEW_ATTRIBUTE_NAMES.SPORT_ENTITY_TWO_ID,
		block.widgetId,
	);
	const playerEntityOneSeasonId = setSportEntityWithSeasonParameter(
		block.options.player1TournamentSeasonId,
		WIDGET_PREVIEW_ATTRIBUTE_NAMES.SPORT_ENTITY_ONE_SEASON_ID,
		block.widgetId,
	);
	const playerEntitySeasonTwoId = setSportEntityWithSeasonParameter(
		block.options.player2TournamentSeasonId,
		WIDGET_PREVIEW_ATTRIBUTE_NAMES.SPORT_ENTITY_TWO_SEASON_ID,
		block.widgetId,
	);
	const teamEntityOneId = setSportEntityWithSeasonParameter(
		block.options.team1Id,
		WIDGET_PREVIEW_ATTRIBUTE_NAMES.SPORT_ENTITY_ONE_ID,
		block.widgetId,
	);
	const teamEntityTwoId = setSportEntityWithSeasonParameter(
		block.options.team2Id,
		WIDGET_PREVIEW_ATTRIBUTE_NAMES.SPORT_ENTITY_TWO_ID,
		block.widgetId,
	);
	const teamEntityOneSeasonId = setSportEntityWithSeasonParameter(
		block.options.team1TournamentSeasonId,
		WIDGET_PREVIEW_ATTRIBUTE_NAMES.SPORT_ENTITY_ONE_SEASON_ID,
		block.widgetId,
	);
	const teamEntitySeasonTwoId = setSportEntityWithSeasonParameter(
		block.options.team2TournamentSeasonId,
		WIDGET_PREVIEW_ATTRIBUTE_NAMES.SPORT_ENTITY_TWO_SEASON_ID,
		block.widgetId,
	);
	const teamIds = block.options.teamIds && block.options.teamIds.length > 0 ? buildTeamIdsParameter(block.options.teamIds) : ``;
	const teamEntityOne = setTeamSportEntityParameter(block.options.team1Id, WIDGET_PREVIEW_ATTRIBUTE_NAMES.SPORT_ENTITY_ONE, block.widgetId);
	const teamEntityTwo = setTeamSportEntityParameter(block.options.team2Id, WIDGET_PREVIEW_ATTRIBUTE_NAMES.SPORT_ENTITY_TWO, block.widgetId);
	const roundsFilter =
		checkIfCorrectWidgetTypes(block.widgetId, [
			WidgetsV2Ids.widgetBasketballTournamentProgramme,
			WidgetsV2Ids.widgetBasketballTeamProgramme,
			WidgetsV2Ids.widgetTennisAthleteProgramme,
			WidgetsV2Ids.widgetTennisTournamentProgramme,
		]) && block.options.roundsFilter
			? constructDataPreviewRoundsFilterAttribute(block.options.roundsFilter, WIDGET_PREVIEW_ATTRIBUTE_NAMES.ROUNDS_FILTER, block.widgetId)
			: '';

	const tournamentId = checkIfCorrectWidgetTypes(block.widgetId, [
		WidgetsV2Ids.widgetTennisAthleteProgramme,
		WidgetsV2Ids.widgetTennisTournamentProgramme,
	])
		? constructDataPreviewAttribute(block.options.tournamentId, WIDGET_PREVIEW_ATTRIBUTE_NAMES.TOURNAMENT)
		: ``;
	const seasonYear = checkIfCorrectWidgetTypes(block.widgetId, [
		WidgetsV2Ids.widgetTennisAthleteProgramme,
		WidgetsV2Ids.widgetTennisTournamentProgramme,
	])
		? constructDataPreviewAttribute(block.options.season, WIDGET_PREVIEW_ATTRIBUTE_NAMES.SEASON)
		: ``;
	const refreshTime = block.options.refreshTime
		? constructDataPreviewAttribute(block.options.refreshTime, WIDGET_PREVIEW_ATTRIBUTE_NAMES.REFRESH_TIME)
		: '';
	const amount = constructDataPreviewAttribute(block.options.amount, WIDGET_PREVIEW_ATTRIBUTE_NAMES.AMOUNT);
	const fixed = constructDataPreviewAttribute(
		block.options.fixed !== undefined ? block.options.fixed.toString() : undefined,
		WIDGET_PREVIEW_ATTRIBUTE_NAMES.FIXED,
	);
	const highLightTeams = block.options.highlightTeams ? buildHighlightTeamsParameter(block.options.highlightTeams) : ``;

	const gender = checkIfCorrectWidgetTypes(block.widgetId, [WidgetsV2Ids.widgetTennisTournamentProgramme])
		? constructDataPreviewAttribute(block.options.gender, WIDGET_PREVIEW_ATTRIBUTE_NAMES.GENDER)
		: '';

	const gameType = checkIfCorrectWidgetTypes(block.widgetId, [
		WidgetsV2Ids.widgetTennisTournamentProgramme,
		WidgetsV2Ids.widgetTennisAthleteProgramme,
	])
		? constructDataPreviewAttribute(block.options.gameType, WIDGET_PREVIEW_ATTRIBUTE_NAMES.GAME_TYPE)
		: '';

	const params = `${startPosition}${limit}${offset}${oddProviderId}${oddDisplay}${oddMarket}${marketValueType}${mainOddProviderId}${fansUnitedEnabled}${fansUnitedExpanded}${date}${headerDisplay}${sortDirection}${sortDirectionFixtures}${sortDirectionResults}${displayTabs}${defaultTab}${headerDefaultOption}${matchType}${homeTeamColor}${awayTeamColor}${roundsFilter}${tournamentId}${seasonYear}${standingTypeSubcategory}${teamEntityOne}${teamEntityTwo}${refreshTime}${amount}${fixed}${gender}${gameType}`;

	return `${monoRepoUrl}iframe.html?id=${constructBaseUrl(
		block.widgetId,
		block,
		sport,
	)}&args=${eventId}${competitionId}${seasonId}${stageId}${`${roundId}`
		.split(',')
		.join(
			'',
		)}${teamId}${playerId}${playerEntityOneId}${playerEntityTwoId}${playerEntitySeasonTwoId}${playerEntityOneSeasonId}${teamEntityOneId}${teamEntityTwoId}${teamEntityOneSeasonId}${teamEntitySeasonTwoId}${chosenTabs}${`${groups}`
		.split(',')
		.join('')}${`${highLightPlayers}`.split(',').join('')}${`${highLightTeams}`.split(',').join('')}${`${teamIds}`
		.split(',')
		.join('')}${`${elements}`.split(',').join('')}${params}${buildSDKOptions(block)}&viewMode=story`;
};

const buildSDKOptions = (block: BlockConfigModel) => {
	const competitionList = block.options.defaultCompetitionList
		? constructDataPreviewAttribute(block.options.defaultCompetitionList, WIDGET_PREVIEW_ATTRIBUTE_NAMES.COMPETITION_LIST)
		: '';
	const configLanguage = block.options.lang
		? constructDataPreviewAttribute(block.options.lang, WIDGET_PREVIEW_ATTRIBUTE_NAMES.LANGUAGE)
		: '';
	const project = constructDataPreviewAttribute('sportal365', WIDGET_PREVIEW_ATTRIBUTE_NAMES.PROJECT);
	const configOddClient =
		block.options.oddClient && block.options.display
			? constructDataPreviewAttribute(block.options.oddClient, WIDGET_PREVIEW_ATTRIBUTE_NAMES.ODD_CLIENT)
			: '';

	return `${competitionList}${configLanguage}${configOddClient}${project}`;
};

const buildStandingsGroupsParameter = (groups: number[]) => {
	return groups.map((group: number, index: number) => {
		return `dataGroups[${index}]:${group};`;
	});
};

const buildRoundsParameter = (rounds: string[]) => {
	return (
		rounds &&
		rounds.map((round: string, index: number) => {
			return `dataRounds[${index}]:${round};`;
		})
	);
};

const formatBasicElementsParameters = (elements: any[]): string[] => {
	return elements.map((element: string, index: number) => {
		return `dataElements[${index}]:${element};`;
	});
};

const formatNestedElementsParameters = (elements: any[], nestedParamName: string): string[] => {
	return elements.map((element: string, index: number) => {
		return `dataElements[${nestedParamName}][${index}]:${element};`;
	});
};

const overwriteStorybookElements = (elements: any[], overwriteCount: number): string[] => {
	const elementsCount = elements.length;
	const diffOverwriteAndElementsCount = overwriteCount - elementsCount; // will calculate how many elements should be overwritten
	if (diffOverwriteAndElementsCount > 0) {
		let combinedArray = [...elements];
		for (let index = 0; index < diffOverwriteAndElementsCount; index++) {
			combinedArray.push(storybookUndefinedValue);
		}
		return combinedArray;
	}

	return elements;
};

const overwriteStorybookTabsElements = (elements: string[], availableOptionElements: string[]): Record<string, string>[] => {
	return availableOptionElements.map((el) => {
		return {
			label: el,
			value: elements.includes(el) ? el : storybookUndefinedValue,
		};
	});
};

// when previewing the widget by storybook -> it holds the widgets data itself
// when we don't explicitly overwrite the elements in the iframe's url -> the preview is incorrect
// this methods holds the logic to overwrite specific count of elements
const overwriteStorybookElementsParameters = (elements: any[], overwriteCount: number): string[] => {
	const elementsForFormatting = overwriteStorybookElements(elements, overwriteCount);
	return formatBasicElementsParameters(elementsForFormatting);
};

const overwriteStorybookNestedElementsParameters = (elements: any[], overwriteCount: number, nestedParamName: string): string[] => {
	const elementsForFormatting = overwriteStorybookElements(elements, overwriteCount);
	return formatNestedElementsParameters(elementsForFormatting, nestedParamName);
};

const buildElementsParameter = (elements: any, widgetId: string, block: BlockConfigModel) => {
	switch (widgetId) {
		case WidgetsV2Ids.widgetFootballTopScorers:
			return formatBasicElementsParameters(elements);
		case WidgetsV2Ids.widgetFootballTeamH2H:
			return overwriteStorybookElementsParameters(elements, 8);
		case WidgetsV2Ids.widgetFootballTeamH2HMatch:
			return overwriteStorybookElementsParameters(elements, 16);
		case WidgetsV2Ids.widgetFootballTeamSquad:
			return overwriteStorybookElementsParameters(elements, 10);
		case WidgetsV2Ids.widgetFootballMostDecoratedPlayers:
		case WidgetsV2Ids.widgetFootballPlayerH2H:
			return overwriteStorybookElementsParameters(elements, 10);
		case WidgetsV2Ids.widgetFootballTeam:
		case WidgetsV2Ids.widgetFootballPlayer:
			const parameters = JSON.parse(elements);
			const infoParameters =
				parameters.info && parameters.info.length > 0 ? overwriteStorybookNestedElementsParameters(parameters.info, 5, 'info') : '';
			const statsParameters =
				parameters.stats && parameters.stats.length > 0 ? overwriteStorybookNestedElementsParameters(parameters.stats, 10, 'stats') : '';
			return `${infoParameters}`.split(',').join('') + `${statsParameters}`.split(',').join('');

		case WidgetsV2Ids.widgetFootballLineups:
			const ELEMENTS_LIMIT = 3;
			const data = elements.map((el: any) => {
				return JSON.parse(el);
			});

			if (data && data.length === 0) {
				return (
					`dataElements[0]:${storybookUndefinedValue};`.split(',').join('') +
					`dataElements[1]:${storybookUndefinedValue};`.split(',').join('') +
					`dataElements[2]:${storybookUndefinedValue};`.split(',').join('')
				);
			}

			const previewData = data.map((item: any, index: number) => {
				if (data.length < ELEMENTS_LIMIT) {
					if (data.length === 1 && index === 0) {
						return (
							`dataElements[${index}].name:${item.name};`.split(',').join('') +
							`dataElements[${index}].status:${item.status};`.split(',').join('') +
							`dataElements[1]:${storybookUndefinedValue};`.split(',').join('') +
							`dataElements[2]:${storybookUndefinedValue};`.split(',').join('')
						);
					} else if (data.length === 2 && index === 1) {
						if (item.name === LINEUPS_ELEMENTS.firstTeam) {
							return (
								`dataElements[${index}].name:${item.name};`.split(',').join('') +
								`dataElements[${index}].status:${item.status};`.split(',').join('') +
								`dataElements[2]:${storybookUndefinedValue};`.split(',').join('')
							);
						} else {
							return (
								`dataElements[${index}].name:${item.name};`.split(',').join('') +
								`dataElements[${index}].status:${item.status};`.split(',').join('') +
								`dataElements[2]:${storybookUndefinedValue};`.split(',').join('')
							);
						}
					}
				} else {
					return (
						`dataElements[${index}].name:${item.name};`.split(',').join('') + `dataElements[${index}].status:${item.status};`.split(',').join('')
					);
				}
			});

			return (
				`${previewData[0] ? previewData[0] : ''}`.split(',').join('') +
				`${previewData[1] ? previewData[1] : ''}`.split(',').join('') +
				`${previewData[2] ? previewData[2] : ''}`.split(',').join('')
			);
		default:
			return '';
	}
};

const buildHighlightPlayersParameter = (players: number[]) => {
	return players && players.length > 0
		? players.map((player: number | string, index: number) => {
				return `${WIDGET_PREVIEW_ATTRIBUTE_NAMES.HIGHLIGHT_ENTITIES}[${index}]:${player};`;
		  })
		: `${WIDGET_PREVIEW_ATTRIBUTE_NAMES.HIGHLIGHT_ENTITIES}:${storybookUndefinedValue};`;
};

const buildHighlightTeamsParameter = (teams: number[]) => {
	return teams && teams.length > 0
		? teams.map((team: number | string, index: number) => {
				return `${WIDGET_PREVIEW_ATTRIBUTE_NAMES.HIGHLIGHT_ENTITIES}[${index}]:${team};`;
		  })
		: `${WIDGET_PREVIEW_ATTRIBUTE_NAMES.HIGHLIGHT_ENTITIES}:${storybookUndefinedValue};`;
};

const buildTeamIdsParameter = (teamIds: number[]) => {
	return (
		teamIds &&
		teamIds.map((id: number, index: number) => {
			return `dataTeamIds[${index}]:${id};`;
		})
	);
};

const constructBaseUrl = (widgetId: string, block: BlockConfigModel, sport: string) => {
	switch (widgetId) {
		case WidgetsV2Ids.widgetFootballSingleEvent:
			return `${SportTypes.FOOTBALL}-widgets-single-event-widget--single-event-widget`;
		case WidgetsV2Ids.widgetBasketballSingleEvent:
			return `${SportTypes.BASKETBALL}-widgets-single-event-widget--single-event-widget`;
		case WidgetsV2Ids.widgetFootballLivescore:
			return `${SportTypes.FOOTBALL}-widgets-livescore-widget--livescore-widget`;
		case WidgetsV2Ids.widgetBasketballLivescore:
			return `${SportTypes.BASKETBALL}-widgets-livescore-widget--livescore-widget`;
		case WidgetsV2Ids.widgetBasketballStandings:
			return `${SportTypes.BASKETBALL}-widgets-standings-widget--standings-widget`;
		case WidgetsV2Ids.widgetBasketballTournamentProgramme:
			return `${SportTypes.BASKETBALL}-widgets-tournament-programme-widget--tournament-programme-widget`;
		case WidgetsV2Ids.widgetBasketballTeamProgramme:
			return `${SportTypes.BASKETBALL}-widgets-team-programme-widget--team-programme-widget`;
		case WidgetsV2Ids.widgetTennisSingleEvent:
			return `${SportTypes.TENNIS}-widgets-single-event-widget--single-event-widget`;
		case WidgetsV2Ids.widgetTennisLivescore:
			return `${SportTypes.TENNIS}-widgets-livescore-widget--livescore-widget`;
		case WidgetsV2Ids.widgetTennisRanking:
			return `${SportTypes.TENNIS}-widgets-ranking-widget--ranking-widget`;
		case WidgetsV2Ids.widgetTennisPlayoff:
			return `${SportTypes.TENNIS}-widgets-playoff-widget--playoff-widget`;
		case WidgetsV2Ids.widgetTennisAthleteProgramme:
			return `${SportTypes.TENNIS}-widgets-athlete-programme-widget--${SportTypes.TENNIS}-athlete-programme-widget`;
		case WidgetsV2Ids.widgetTennisTournamentProgramme:
			return `${SportTypes.TENNIS}-widgets-tournament-programme-widget--${SportTypes.TENNIS}-tournament-programme-widget`;
		case WidgetsV2Ids.widgetFootballStandings:
			if (block && block.options && block.options.groups && block.options.groups.length > 0) {
				return `${SportTypes.FOOTBALL}-widgets-${widgetId}-widget--group-${widgetId}`;
			} else {
				return `${SportTypes.FOOTBALL}-widgets-${widgetId}-widget--league-${widgetId}`;
			}
		case WidgetsV2Ids.widgetFootballTeamProgramme:
			return `${SportTypes.FOOTBALL}-widgets-programme-widget--programme-widget`;
		case WidgetsV2Ids.widgetFootballPlayerH2H:
			return `${SportTypes.FOOTBALL}-widgets-player-h2h-widget--player-h-2-h-widget`;
		case WidgetsV2Ids.widgetFootballMatchesH2H:
			return `${SportTypes.FOOTBALL}-widgets-matches-h2h-widget--matches-h-2-h-widget`;
		case WidgetsV2Ids.widgetFootballTeamH2H:
			return `${SportTypes.FOOTBALL}-widgets-team-h2h-widget--team-h-2-h-widget`;
		case WidgetsV2Ids.widgetFootballTeamH2HMatch:
			return `${SportTypes.FOOTBALL}-widgets-team-h2h-match-widget--team-match-h-2-h-widget`;
		case WidgetsV2Ids.widgetFootballMostDecoratedPlayers:
			return `${SportTypes.FOOTBALL}-widgets-most-decorated-players-widget--most-decorated-players-widget`;
		case WidgetsV2Ids.widgetFootballLineups:
			return `${SportTypes.FOOTBALL}-widgets-lineups-widget--lineups-widget`;
		case WidgetsV2Ids.widgetFootballOdds:
			return `${SportTypes.FOOTBALL}-widgets-${WidgetsV2Ids.widgetFootballOdds}-widget--${WidgetsV2Ids.widgetFootballOdds}-widget`;
		default:
			return `${sport}-widgets-${widgetId}-widget--${widgetId}-widget`;
	}
};

const setFansUnitedEnabled = (block: BlockConfigModel, widgetId: string) => {
	if (
		widgetId &&
		(checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballSingleEvent) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballTeam) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballPlayer)) &&
		block.options.fansUnitedEnabled
	) {
		return constructDataPreviewAttribute(block.options.fansUnitedEnabled, WIDGET_PREVIEW_ATTRIBUTE_NAMES.FANS_UNITED_ENABLED);
	} else if (
		widgetId &&
		(checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballSingleEvent) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballTeam) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballPlayer)) &&
		!block.options.fansUnitedEnabled
	) {
		return `fansUnitedEnabled:${false};`;
	} else {
		return '';
	}
};

const setFansUnitedExpanded = (block: BlockConfigModel, widgetId: string) => {
	if (
		widgetId &&
		(checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballSingleEvent) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballTeam) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballPlayer)) &&
		block.options.fansUnitedEnabled &&
		block.options.fansUnitedExpanded
	) {
		return constructDataPreviewAttribute(block.options.fansUnitedExpanded, WIDGET_PREVIEW_ATTRIBUTE_NAMES.FANS_UNITED_EXPANDED);
	} else if (
		widgetId &&
		(checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballSingleEvent) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballTeam) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballPlayer)) &&
		(!block.options.fansUnitedEnabled || !block.options.fansUnitedExpanded)
	) {
		return `fansUnitedExpanded:${false};`;
	} else {
		return ``;
	}
};

const setDefaultHeaderDisplayOption = (widgetId: string, attribute: boolean) => {
	if (
		widgetId &&
		(checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetBasketballStandings) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetBasketballLivescore) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetBasketballTeamProgramme) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetBasketballTournamentProgramme) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetTennisLivescore) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetTennisPlayoff) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetTennisTournamentProgramme) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetTennisAthleteProgramme) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballPlayerH2H) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballTeamH2H) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballLineups) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballMatchesH2H) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballTeamProgramme) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballTournamentProgramme) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballStandings) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballTeamSquad) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballLivescore))
	) {
		return `dataHeaderDisplay:${attribute};`;
	} else {
		return '';
	}
};

const setSportEntityWithSeasonParameter = (attribute: any, propertyName: string, widgetId: string) => {
	if (
		(widgetId && checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballTeamH2H)) ||
		checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballPlayerH2H) ||
		checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballTeamH2HMatch)
	) {
		return constructDataPreviewAttribute(attribute, propertyName);
	} else {
		return ``;
	}
};

const setTeamSportEntityParameter = (attribute: any, propertyName: string, widgetId: string) => {
	if (widgetId && checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballMatchesH2H)) {
		return constructDataPreviewAttribute(attribute, propertyName);
	} else {
		return ``;
	}
};

const returnCorrectEventPropertyByBlockType = (block: BlockConfigModel) => {
	if (block.widgetId && checkIfCorrectWidgetType(block.widgetId, WidgetsV2Ids.widgetBasketballSingleEvent)) {
		return block.options.gameId;
	} else {
		return block.options.matchId;
	}
};
